import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FormatDate from '../Report/FormatDate';
import LoadingSpinner from '../Report/LoadingSpinner';
import { Button } from 'primereact/button';
import Intersection from '../Plan/intersection';
import { FilterMatchMode } from 'primereact/api';
import { Paginator } from 'primereact/paginator';
import { imageTemplate } from '../Report/ProductInprogress';

const DelayProgress = ({ productInlateFactory, onPaginationChange }) => {
  const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
  const [selectFactoryCode, setSelectFactoryCode] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'Building.buildingCode': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    orderCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
    intendFinishDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const handleTeamProgress = (rowData) => {
    setSelectFactoryCode(rowData.factoryCode);
    setDialogLocationVisible(true);
  };

  const onPageChange = (event) => {
    const newFirst = event.first;
    const newRows = event.rows;

    setFirst(newFirst);
    setRows(newRows);
    if (onPaginationChange) {
      onPaginationChange(newFirst, newRows);
    }
  };

  return (
    <div className='border-round-lg p-3 bg-white'>
      <p
        style={{
          color: '#5c5c5c',
          fontWeight: '700',
          marginBottom: '2rem',
        }}>
        Theo dõi vật tư
      </p>
      {productInlateFactory && productInlateFactory.data ? (
        <div>
          <DataTable
            value={productInlateFactory?.data?.items}
            rowClassName='custom-row-class-factory-inprogress'
            size='small'
            emptyMessage='Không có dữ liệu'
            scrollable
            scrollHeight='400px'
            filters={filters}
            onFilter={(e) => setFilters(e.filters)}>
            <Column
              header='STT'
              body={(rowData, options) => (
                <div className='text-center'>
                  {first + options.rowIndex + 1}
                </div>
              )}
              style={{ width: '3%' }}
              alignHeader={'center'}
              align={'center'}></Column>
            <Column
              header='Tên công trình'
              field='Building.name'
              style={{ minWidth: '100px' }}
              filter
            />
            <Column
              header='Mã đơn hàng'
              field='orderCode'
              style={{ minWidth: '100px' }}
              filter
            />
            <Column
              header='Mã nhà máy'
              field='factoryCode'
              style={{ minWidth: '150px' }}
              filter
            />
            <Column
              header='Hạng mục'
              field='categoryName'
              style={{ lineHeight: '1.5', minWidth: '120px' }}
              filter
            />
            <Column
              header='Hình ảnh'
              style={{ minWidth: '100px' }}
              body={imageTemplate}
              alignHeader={'center'}
              align={'center'}></Column>
            <Column
              header='SL'
              field='quantity'
              style={{ minWidth: '50px' }}
            />
            <Column
              header='ĐVT'
              field='unit'
              style={{ minWidth: '50px' }}
              filter
            />
            <Column
              header='Mã sản phẩm'
              field='productCode'
              style={{ lineHeight: '1.5', minWidth: '120px' }}
              filter
            />

            <Column
              header='Tên vật tư chính'
              field='materialName'
              style={{ minWidth: '100px' }}
              filter
            />
            <Column
              header='Tên hạng mục gia công'
              field='categoryMaterial'
              style={{ minWidth: '100px' }}
              filter
              body={(rowData) =>
                rowData.notMachining
                  ? 'Không cần hàng gia công'
                  : rowData.categoryMaterial
              }
            />
            <Column
              header='Leadtime vật tư'
              // field="categoryMaterial"
              style={{ minWidth: '100px' }}
              filter
              body={(rowData) => (
                <span>{FormatDate(rowData.firstMaterial)}</span>
              )}
            />
            <Column
              header='Leadtime hàng gia công'
              // field="categoryMaterial"
              style={{ minWidth: '100px' }}
              filter
              body={(rowData) => (
                <span>{FormatDate(rowData.firstMachining)}</span>
              )}
            />

            <Column
              header='Ngày ra BOM'
              field='endedDate'
              body={(rowData) => (
                <span>{FormatDate(rowData.bomProductionDate)}</span>
              )}
              style={{ minWidth: '100px' }}
              sortable
              align='center'
              alignHeader='center'
            />
            <Column
              header='Định vị'
              body={(rowData) => (
                <div className='text-center'>
                  <Button
                    onClick={() => handleTeamProgress(rowData)}
                    icon='pi pi-map-marker'
                    rounded
                    text
                    severity='secondary'
                    aria-label='Bookmark'
                    style={{ color: 'black' }}
                  />
                  <p>{rowData.teamWorking}</p>
                </div>
              )}
              alignHeader={'center'}
              align={'center'}
              style={{ minWidth: '100px' }}
            />
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={productInlateFactory?.data?.totalItems}
            rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
            onPageChange={onPageChange}
          />
          {dialogLocationVisible && (
            <Intersection
              visible={dialogLocationVisible}
              onHide={() => setDialogLocationVisible(false)}
              factoryCode={selectFactoryCode}
            />
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default DelayProgress;
