import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { TabMenu } from 'primereact/tabmenu';
import '../../assets/scss/theme-base/QuantityTemplate.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import tabMenuTemplate from '../../assets/templete/tabMenuTemplete';
import ModalAddPlan from './ModalAddPlan';
import ModalAddComplete from './ModalAddComplete';
import ActualComplete from './ActualComplete';
import ModalAddPrice from './ModalAddPrice';
import { statusTemplate, formatNumber } from '../../components/utils';
import {
  getOrder,
  getBuilding,
  setDataNull2,
  exportExcel4,
} from '../../store/order/slice';
import Intersection from '../Plan/intersection';
import { site } from '../../api/url_helper';
import { Paginator } from 'primereact/paginator';
import LoadingSpinner from '../Report/LoadingSpinner';
import ModalExcel from './ModalExcel';
import { exportQuantityPDF } from './exportQuantityPDF';
import { Image } from 'primereact/image';
import { Menu } from 'primereact/menu';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';

export default function Production() {
  const [dialogLocationVisible, setDialogLocationVisible] = useState(false);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [selectFactoryCode, setSelectFactoryCode] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [categoryValue, setCategoryValue] = useState('');
  const [factoryCodeValue, setFactoryCodeValue] = useState('');
  const [buildingValue, setBuildingValue] = useState();
  const [category, setCategory] = useState(null);
  const [factoryCode, setFactoryCode] = useState(null);
  const [building, setBuilding] = useState(null);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [debounceTimeouts, setDebounceTimeouts] = useState({});
  const [selectedProductPrice, setSelectedProductPrice] = useState(null);
  const [dialogImportVisible, setDialogImportVisible] = useState(false);
  const downloadLink = useRef(null);
  const [excelFileUrl, setExcelFileUrl] = useState('');
  const menuRight = useRef(null);
  const defaultImageUrl =
    'https://static.thenounproject.com/png/1269202-200.png';

  const listPermissions =
    JSON.parse(localStorage.getItem('userPermissions')) || {};

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'Building.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    quantity: { value: null, matchMode: FilterMatchMode.CONTAINS },
    unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    factoryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    price: { value: null, matchMode: FilterMatchMode.CONTAINS },
    priceNote: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const {
    orderData,
    dataBuilding,
    addPriceData,
    exportExcelData4,
    exportExcelError4,
    loadingExportExcel,
    importData4,
  } = useSelector(
    (state) => ({
      orderData: state.Production.orderData,
      dataBuilding: state.Production.dataBuilding,
      addPriceData: state.Production.addPriceData,
      exportExcelData4: state.Production.quantityExportExcel.data,
      exportExcelError4: state.Production.quantityExportExcel.error,
      loadingExportExcel: state.Production.quantityExportExcel.loading,
      importData4: state.Production.importData4,
    }),
    shallowEqual
  );

  useEffect(() => {
    let status = 'Tất cả';
    if (orderData) {
      status = orderData.data.listStatus[tabMenuActive]?.label;
    }
    if (!visible3 && !dialogLocationVisible) {
      dispatch(
        getOrder({
          page: (first + rows) / rows,
          limit: rows,
          status,
          category: category,
          factoryCode: factoryCode,
          building: building,
          current: 'quantity',
        })
      );
    }
  }, [
    dispatch,
    first,
    tabMenuActive,
    rows,
    category,
    factoryCode,
    building,
    visible3,
    dialogLocationVisible,
  ]);

  useEffect(() => {
    if (addPriceData || importData4) {
      dispatch(
        getOrder({
          page: (first + rows) / rows,
          limit: rows,
          current: 'quantity',
        })
      );
      dispatch(setDataNull2());
    }
  }, [dispatch, first, rows, addPriceData, importData4]);

  useEffect(() => {
    const updateDataTable = () => {
      if (orderData) {
        setDataTable(orderData.data.products.items);
      }
    };
    updateDataTable();
  }, [orderData]);

  useEffect(() => {
    dispatch(
      getBuilding({
        page: 1,
        limit: 1000,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (dataBuilding?.data?.items) {
      const buildings = dataBuilding.data.items.map((item) => ({
        label: item.name,
        value: item.buildingCode,
      }));
      setBuildingOptions(removeDuplicates(buildings, 'value'));
    }
  }, [dataBuilding]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleRowSelect = (rowData) => {
    setSelectedProductId(rowData.id);
    setSelectedProductPrice(rowData.price);
    setVisible(true);
  };

  const handleRowSelect2 = (rowData) => {
    setSelectedProductId(rowData.id);
    setSelectedProductPrice(rowData.price);
    setVisible2(true);
  };

  const handleRowSelect4 = (rowData) => {
    setSelectedProductId(rowData.id);
    setSelectedProductPrice(rowData.price);
    setVisible4(true);
  };

  const handleTeamProgress = (rowData) => {
    setSelectFactoryCode(rowData.factoryCode);
    setDialogLocationVisible(true);
  };

  const priceTemplate = (rowData) => {
    return formatNumber(rowData.price);
  };

  const quantityTemplate = (rowData) => {
    return formatNumber(rowData.quantity);
  };

  const handleCategoryChange = (e) => {
    setCategoryValue(e.target.value);
    debounce(() => setCategory(e.target.value), 'category');
  };

  const handleBuildingChange = (e) => {
    setBuilding(e.target.value);
    setBuildingValue(e.target.value);
  };

  const handleFactoryCodeChange = (e) => {
    setFactoryCodeValue(e.target.value);
    debounce(() => setFactoryCode(e.target.value), 'factoryCode');
  };

  const debounce = (callback, field) => {
    if (debounceTimeouts[field]) {
      clearTimeout(debounceTimeouts[field]);
    }

    const timeout = setTimeout(() => {
      callback();
      setDebounceTimeouts((prev) => ({ ...prev, [field]: null }));
    }, 400);

    setDebounceTimeouts((prev) => ({ ...prev, [field]: timeout }));
  };

  const removeDuplicates = (array, key) => {
    return array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t[key] === item[key])
    );
  };

  const logDataTable = () => {
    const ID = dataBuilding?.data?.items?.find(
      (item) => item.buildingCode === building
    )?.id;
    if (ID) {
      dispatch(
        exportExcel4({
          type: 'quantity',
          building_id: ID,
        })
      );
    } else {
      dispatch(
        exportExcel4({
          type: 'quantity',
        })
      );
    }
  };

  useEffect(() => {
    if (exportExcelData4) {
      const url = `${site}/${exportExcelData4.file}`;
      setExcelFileUrl(url);
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xuất excel thành công.',
        life: 3000,
      });
      dispatch(setDataNull2());

      // Tự động tải file
      downloadLink.current.href = url;
      // downloadLink.current.download = 'exported_data.xlsx';
      downloadLink.current.click();
    }
  }, [dispatch, exportExcelData4]);

  useEffect(() => {
    if (exportExcelError4) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: exportExcelError4.response.data.message,
        life: 3000,
      });
      dispatch(setDataNull2());
    }
  }, [dispatch, exportExcelError4]);

  const items = [];

  if (listPermissions['Sản lượng']?.includes('Cập nhật thông tin')) {
    items.push({
      label: 'Nhập từ excel',
      icon: 'pi pi-file-excel',
      command: () => setDialogImportVisible(true),
    });
  }

  items.push({
    label: 'Xuất ra excel',
    icon: 'pi pi-file-excel',
    command: logDataTable,
  });

  items.push({
    label: 'In báo cáo',
    icon: 'pi pi-print',
    command: () => exportQuantityPDF(dataTable),
  });

  const handleClick = (event) => {
    menuRight.current.show(event);
  };

  const imageTemplate = (rowData) => {
    return (
      <Image
        src={rowData?.categoryImage || defaultImageUrl}
        alt='Category Image'
        imageStyle={{ borderRadius: '20%', width: '40px', height: '40px' }}
        preview
        onError={(e) => (e.target.src = defaultImageUrl)}
      />
    );
  };

  const width = window.innerWidth;

  return (
    <div>
      <Toast ref={toast} />
      <a
        ref={downloadLink}
        style={{ display: 'none' }}
      />
      <div className='flex mb-3 gap-3 justify-content-between xl:flex-row flex-column'>
        <div className='flex flex-column md:flex-row gap-3 xl:w-9'>
          <IconField className='search-item'>
            <Dropdown
              placeholder='Lọc công trình'
              options={buildingOptions}
              onChange={handleBuildingChange}
              emptyFilterMessage='Không có dữ liệu'
              emptyMessage='Không có dữ liệu'
              value={buildingValue}
              filter
              showClear
              className='border-0'
            />
          </IconField>
          <IconField className='search-item'>
            <InputText
              value={categoryValue}
              onChange={handleCategoryChange}
              placeholder='Lọc hạng mục'
              className='border-0'
            />
          </IconField>
          <IconField className='search-item'>
            <InputText
              value={factoryCodeValue}
              onChange={handleFactoryCodeChange}
              placeholder='Lọc mã nhà máy'
              className='border-0'
            />
          </IconField>
        </div>
        <div className='flex xl:w-3 gap-3 justify-content-end'>
          <Button
            label={loadingExportExcel ? 'Đang xuất...' : 'Thao tác'}
            popup='true'
            icon={
              loadingExportExcel
                ? 'pi pi-spin pi-spinner'
                : 'pi pi-sort-down-fill'
            }
            disabled={loadingExportExcel}
            onClick={handleClick}
            aria-controls='popup_menu_right'
            aria-haspopup='true'
            iconPos='right'
          />
          <Menu
            model={items}
            popup
            ref={menuRight}
            id='popup_menu_right'
            popupAlignment='right'
          />
        </div>
      </div>

      <div className='bg-white border-round-md shadow-1 w-full px-3 overflow-hidden'>
        {orderData && orderData.data ? (
          <div className='card overflow-hidden '>
            <div className='card overflow-hidden'>
              {orderData.data.listStatus && (
                <TabMenu
                  model={orderData.data.listStatus.map((item, index) => ({
                    ...item,
                    template: () =>
                      tabMenuTemplate(
                        item,
                        index,
                        tabMenuActive,
                        setTabmenuActive
                      ),
                  }))}
                  activeIndex={tabMenuActive}
                  onTabChange={(e) => setTabmenuActive(e.index)}
                  pt={{ menuitem: 'p-0' }}
                />
              )}
            </div>

            <div className='overflow-hidden'>
              <DataTable
                value={dataTable}
                emptyMessage='Không có dữ liệu'
                size='small'
                scrollable
                scrollHeight='68vh'
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}>
                <Column
                  header='STT'
                  body={(rowData, options) => (
                    <div className='text-center'>
                      {first + options.rowIndex + 1}
                    </div>
                  )}
                  frozen
                  style={{ width: '3%', minWidth: '60px' }}
                  alignHeader='center'
                  align='center'
                />
                <Column
                  header='Tên công trình'
                  field='Building.name'
                  style={{ minWidth: '200px' }}
                  filter
                  frozen
                  filterPlaceholder='Tìm kiếm mã công trình'
                />
                <Column
                  header='Tên sản phẩm'
                  field='categoryName'
                  style={{ minWidth: '150px' }}
                  filter
                  frozen
                  filterPlaceholder='Tìm kiếm tên sản phẩm'
                />
                <Column
                  header='Hình ảnh'
                  body={imageTemplate}
                  style={{ minWidth: '100px' }}
                  align='center'
                  frozen
                />
                <Column
                  header='Mã SP'
                  field='productCode'
                  style={{ minWidth: '100px' }}
                  frozen
                  filter
                  filterPlaceholder='Tìm kiếm mã sản phẩm'
                />
                <Column
                  header='Mã nhà máy'
                  field='factoryCode'
                  filterPlaceholder='Tìm kiếm mã nhà máy'
                  style={{ minWidth: '120px' }}
                  frozen
                  filter
                />
                <Column
                  header='Số lượng'
                  field='quantity'
                  body={quantityTemplate}
                  style={{ minWidth: '90px' }}
                />
                <Column
                  header='ĐVT'
                  field='unit'
                  style={{ minWidth: '50px' }}
                  filter
                  filterPlaceholder='Tìm kiếm đơn vị tính'
                />

                <Column
                  header='Trạng thái'
                  field='status'
                  body={statusTemplate}
                  style={{ minWidth: '150px' }}
                  filter
                  filterPlaceholder='Tìm kiếm trạng thái'
                  alignHeader='center'
                  align='center'
                />
                <Column
                  header='Đơn giá'
                  field='price'
                  style={{ minWidth: '100px' }}
                  body={priceTemplate}
                  filter
                  filterPlaceholder='Tìm kiếm đơn giá'
                />
                <Column
                  header='Sản lượng kế hoạch'
                  body={(rowData) => (
                    <div className='text-center'>
                      <i
                        className='pi pi-calendar cursor-pointer'
                        onClick={() => handleRowSelect(rowData)}></i>
                    </div>
                  )}
                  field='keHoach'
                  style={{ minWidth: '150px' }}
                  alignHeader='center'
                  align='center'
                />
                <Column
                  header='Sản lượng hoàn thành'
                  body={(rowData) => (
                    <div className='text-center py-2'>
                      <i
                        className='pi pi-calendar cursor-pointer'
                        onClick={() => handleRowSelect2(rowData)}></i>
                    </div>
                  )}
                  field='keHoach'
                  style={{ minWidth: '160px' }}
                  alignHeader='center'
                  align='center'
                />
                <Column
                  header='Sản lượng thực tế'
                  body={(rowData) => (
                    <div className='text-center py-2'>
                      <i
                        className='pi pi-calendar cursor-pointer'
                        onClick={() => handleRowSelect4(rowData)}></i>
                    </div>
                  )}
                  field='keHoach'
                  style={{ minWidth: '160px' }}
                  alignHeader='center'
                  align='center'
                />
                <Column
                  header='Ghi chú'
                  field='priceNote'
                  style={{ minWidth: '150px' }}
                />
                <Column
                  header='Định vị'
                  body={(rowData) => (
                    <div className='text-center'>
                      <i
                        onClick={() => handleTeamProgress(rowData)}
                        className='pi pi-map-marker cursor-pointer'
                        style={{ color: 'black' }}></i>
                      <p>{rowData.teamWorking}</p>
                    </div>
                  )}
                  style={{ minWidth: '150px' }}
                  alignHeader='center'
                  align='center'
                />
                {listPermissions['Sản lượng']?.includes(
                  'Cập nhật thông tin'
                ) && (
                  <Column
                    header='Thêm đơn giá'
                    body={(rowData) => (
                      <div className='text-center'>
                        <i
                          onClick={() => {
                            setVisible3(true);
                            setSelectedRowData(rowData);
                          }}
                          className='pi pi-file-import cursor-pointer'
                          style={{ color: 'black' }}></i>
                      </div>
                    )}
                    style={{ minWidth: '100px' }}
                    alignHeader='center'
                    align='center'></Column>
                )}
              </DataTable>
              <div className='card'>
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={orderData?.data?.products?.totalItems}
                  rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
            <ModalAddPlan
              price={selectedProductPrice}
              isShow={visible}
              productId={selectedProductId}
              hiden={() => setVisible(false)}
            />
            <ModalExcel
              onHide={() => setDialogImportVisible(false)}
              visible={dialogImportVisible}
              id={
                dataBuilding?.data?.items?.find(
                  (item) => item.buildingCode === building
                )?.id
              }
            />
            <ModalAddComplete
              price={selectedProductPrice}
              productId={selectedProductId}
              isShow={visible2}
              hiden={() => setVisible2(false)}
            />
            <ActualComplete
              isShow={visible4}
              price={selectedProductPrice}
              productId={selectedProductId}
              hiden={() => setVisible4(false)}
              toast={toast}
            />
            <ModalAddPrice
              isShow={visible3}
              rowData={selectedRowData}
              hiden={() => setVisible3(false)}
              toast={toast}
            />
            {dialogLocationVisible && (
              <Intersection
                visible={dialogLocationVisible}
                onHide={() => setDialogLocationVisible(false)}
                factoryCode={selectFactoryCode}
              />
            )}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
}
