import React, { useEffect, useState, useContext, useRef } from 'react';
import HeaderReport from '../../layout/HeaderReport/HeaderReport';
import HeaderReportFactory from './HeaderReportFactory';
import DonutChart from './DonutChart';
import ProductInprogress from './ProductInprogress';
import ProductInlate from './ProductInlate';
import DonutChartPDF from './DonutChartPDF';
import HeaderReportFactoryPDF from './HeaderReportFactoryPDF';
import {
  getDashboardFactory,
  getProductInlateFactory,
  getProductInprogressFactory,
  setExcelDataBuildingNull,
} from '../../store/dashboard/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import '../../assets/scss/theme-base/dashboard.css';
import FormatDate from './FormatDate';
import { exportFactoryPDF } from './ExportFactoryPDF';
import './ReportFactory.scss';
import { DataContext } from '../../contexts/data/DataProvider';
import { Toast } from 'primereact/toast';
import { site } from '../../api/url_helper';
import { downloadExcelFactory } from '../../store/dashboard/slice';
import { formatDateApi } from '../../feature/formatDateApi';

export default function Report() {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const downloadLink = useRef(null);

  const [building, setBuilding] = useState(null);
  const [orderCode, setOrderCode] = useState('');
  const [category, setCategory] = useState('');
  const [factoryCode, setFactoryCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [nameBuilding, setNameBuilding] = useState('');
  const [pagination, setPagination] = useState({ first: 0, rows: 10 });
  const [pagination1, setPagination1] = useState({ first: 0, rows: 10 });

  const handlePaginationChange = (first, rows) => {
    setPagination({ first, rows });
  };
  const handlePaginationChange1 = (first, rows) => {
    setPagination1({ first, rows });
  };

  const { buildingName } = useContext(DataContext);
  useEffect(() => {
    if (buildingName) setNameBuilding(buildingName);
  }, [buildingName]);

  const {
    dashboardFactoryData,
    productInlateFactory,
    productInprogressFactory,
  } = useSelector(
    (state) => ({
      dashboardFactoryData: state.Dashboard.dashboardFactoryData,
      productInlateFactory: state.Dashboard.productInlateFactory,
      productInprogressFactory: state.Dashboard.productInprogressFactory,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        getProductInlateFactory({
          page: (pagination1.first + pagination1.rows) / pagination1.rows,
          limit: pagination1.rows,
          timeStartReq: startDate,
          timeEndReq: endDate,
          type: 'late',
          building,
          orderCode,
          category,
          factoryCode,
        })
      );
    }
  }, [
    dispatch,
    pagination1,
    startDate,
    endDate,
    building,
    orderCode,
    category,
    factoryCode,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        getProductInprogressFactory({
          page: (pagination.first + pagination.rows) / pagination.rows,
          limit: pagination.rows,
          timeStartReq: startDate,
          timeEndReq: endDate,
          building,
          orderCode,
          category,
          factoryCode,
        })
      );
    }
  }, [
    dispatch,
    startDate,
    endDate,
    building,
    orderCode,
    category,
    factoryCode,
    pagination,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        getDashboardFactory({
          timeStartReq: startDate,
          timeEndReq: endDate,
          building,
          orderCode,
          category,
          factoryCode,
        })
      );
    }
  }, [
    dispatch,
    startDate,
    endDate,
    building,
    orderCode,
    category,
    factoryCode,
  ]);

  //export excel
  const { buildingData, excelDataFactory, errorExcelDataFactory } = useSelector(
    (state) => ({
      excelDataFactory: state.Dashboard.excelDataFactory,
      errorExcelDataFactory: state.Dashboard.errorExcelDataFactory,
      buildingData: state.Production.dataBuilding,
    }),
    shallowEqual
  );

  const exportExcelHandle = () => {
    const id = buildingData?.data?.items.find(
      (item) => item.buildingCode === building
    )?.id;

    if (id) {
      dispatch(
        downloadExcelFactory({
          building_id: id,
          timeStartReq: formatDateApi(startDate),
          timeEndReq: formatDateApi(endDate),
        })
      );
    } else {
      dispatch(
        downloadExcelFactory({
          timeStartReq: formatDateApi(startDate),
          timeEndReq: formatDateApi(endDate),
        })
      );
    }
  };

  useEffect(() => {
    if (excelDataFactory) {
      const url = `${site}/${excelDataFactory.file}`;
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Xuất excel thành công.',
        life: 3000,
      });
      dispatch(setExcelDataBuildingNull());

      // Tự động tải file
      downloadLink.current.href = url;
      downloadLink.current.download = 'exported_data.xlsx';
      downloadLink.current.click();
    }
  }, [dispatch, excelDataFactory]);

  useEffect(() => {
    if (errorExcelDataFactory) {
      toast.current.show({
        severity: 'error',
        summary: 'Thất bại',
        detail: errorExcelDataFactory.response.data.message,
        life: 3000,
      });
      dispatch(setExcelDataBuildingNull());
    }
  }, [dispatch, errorExcelDataFactory]);

  return (
    <div>
      <div>
        <Toast ref={toast} />
        <a
          ref={downloadLink}
          style={{ display: 'none' }}
        />
        <HeaderReport
          building={building}
          orderCode={orderCode}
          category={category}
          factoryCode={factoryCode}
          setBuilding={setBuilding}
          setOrderCode={setOrderCode}
          setCategory={setCategory}
          setFactoryCode={setFactoryCode}
          setStartDateOutput={setStartDate}
          setEndDateOutput={setEndDate}
          exportClick={(e) =>
            exportFactoryPDF(
              'save',
              FormatDate(startDate),
              FormatDate(endDate),
              dashboardFactoryData?.data,
              productInprogressFactory?.data?.items,
              productInlateFactory?.data?.items,
              nameBuilding
            )
          }
          printReport={(e) =>
            exportFactoryPDF(
              'print',
              FormatDate(startDate),
              FormatDate(endDate),
              dashboardFactoryData?.data,
              productInprogressFactory?.data?.items,
              productInlateFactory?.data?.items,
              nameBuilding
            )
          }
          exportExcel={exportExcelHandle}
        />
      </div>
      <HeaderReportFactory dashboardFactoryData={dashboardFactoryData} />
      <div
        id='header-pdf'
        style={{
          width: '1000px',
          height: '100px',
          backgroundColor: 'white',
          position: 'absolute',
          left: '-9999999999px',
          transform: 'scale(0.5)',
        }}>
        <HeaderReportFactoryPDF dashboardFactoryData={dashboardFactoryData} />
      </div>
      <div
        id='donut-chart'
        style={{
          width: '400px',
          height: '400px',
          position: 'absolute',
          left: '-9999999999px',
        }}>
        <DonutChartPDF dashboardFactoryData={dashboardFactoryData} />
      </div>
      <div className='flex mt-3 gap-3 flex-column md:flex-row '>
        <div
          className='bg-white border-round-lg py-4 chart-factory shadow-1'
          style={{ width: 'calc(25% - 11px)' }}>
          <DonutChart dashboardFactoryData={dashboardFactoryData} />
        </div>
        <div
          className='bg-white border-round-lg px-3 shadow-1 chart-factory'
          style={{ width: 'calc(75% - 4px)' }}>
          <ProductInprogress
            productInprogressFactory={productInprogressFactory}
            onPaginationChange={handlePaginationChange}
          />
        </div>
      </div>
      <div className='mt-3 w-full shadow-1 border-round-lg'>
        <ProductInlate
          productInlateFactory={productInlateFactory}
          onPaginationChange={handlePaginationChange1}
        />
      </div>
    </div>
  );
}
