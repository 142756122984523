import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getListStatusSuccess,
  getListStatusFail,
  updateLocationSuccess,
  updateLocationFail,
  deleteLocationSuccess,
  deleteLocationFail,
} from './slice';
import {
  getListStatus,
  updateLocation,
  deleteLocation,
} from '../../api/backend_helper';

function* getListStatusSaga() {
  try {
    const data = yield call(getListStatus);
    yield put(getListStatusSuccess(data));
  } catch (error) {
    yield put(getListStatusFail(error.messagge));
  }
}

function* updateLocationRequest(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateLocation, id, data);
    yield put(updateLocationSuccess(response));
  } catch (error) {
    yield put(updateLocationFail(error));
  }
}

function* deleteLocationRequest(action) {
  try {
    const data = yield call(deleteLocation, action.payload);
    yield put(deleteLocationSuccess(data));
  } catch (error) {
    yield put(deleteLocationFail(error.messagge));
  }
}

function* Product() {
  yield takeEvery('product/getListStatus', getListStatusSaga);
  yield takeEvery('product/updateLocation', updateLocationRequest);
  yield takeEvery('product/deleteLocation', deleteLocationRequest);
}

export default Product;
