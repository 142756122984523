import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getDashboardFactoryFail,
  getDashboardFactorySuccess,
  getProductInprogressFactorySuccess,
  getProductInprogressFactoryFail,
  getProductInlateFactorySuccess,
  getProductInlateFactoryFail,
  getProductIncompleteFactoryFail,
  getProductIncompleteFactorySuccess,
  downloadExcelFactoryFail,
  downloadExcelFactorySuccess,
  downloadExcelBuildingFail,
  downloadExcelBuildingSuccess,
} from './slice';

import {
  getDashboardFactory,
  getProductInprogressFactory,
  getProductInlateFactory,
  getProductIncompleteFactory,
  exportExcelFactory,
  exportExcelBuilding,
} from '../../api/backend_helper';

function* getDashboardFactorySaga(action) {
  try {
    const {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    } = action.payload;
    const data = yield call(getDashboardFactory, {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    });
    yield put(getDashboardFactorySuccess(data));
  } catch (error) {
    yield put(getDashboardFactoryFail(error.message));
  }
}

function* getProductInprogressFactorySaga(action) {
  try {
    const {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    } = action.payload;
    const data = yield call(getProductInprogressFactory, {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    });
    yield put(getProductInprogressFactorySuccess(data));
  } catch (error) {
    yield put(getProductInprogressFactoryFail(error.message));
  }
}

function* getProductInlateFactorySaga(action) {
  try {
    const {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      type,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    } = action.payload;
    const data = yield call(getProductInlateFactory, {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      type,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    });
    yield put(getProductInlateFactorySuccess(data));
  } catch (error) {
    yield put(getProductInlateFactoryFail(error.message));
  }
}

function* getProductIncompleteFactorySaga(action) {
  try {
    const {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      type,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    } = action.payload;
    const data = yield call(getProductIncompleteFactory, {
      page,
      limit,
      timeStartReq,
      timeEndReq,
      type,
      building,
      orderCode,
      category,
      factoryCode,
      current,
    });
    yield put(getProductIncompleteFactorySuccess(data));
  } catch (error) {
    yield put(getProductIncompleteFactoryFail(error.message));
  }
}

function* downloadExcelFactorySaga(action) {
  try {
    const data = yield call(exportExcelFactory, action.payload);
    yield put(downloadExcelFactorySuccess(data));
  } catch (error) {
    yield put(downloadExcelFactoryFail(error));
  }
}

function* downloadExcelBuildingSaga(action) {
  try {
    const data = yield call(exportExcelBuilding, action.payload);
    yield put(downloadExcelBuildingSuccess(data));
  } catch (error) {
    yield put(downloadExcelBuildingFail(error));
  }
}

function* DashboardFactory() {
  yield takeEvery('dashboard/getDashboardFactory', getDashboardFactorySaga);
  yield takeEvery(
    'dashboard/getProductInprogressFactory',
    getProductInprogressFactorySaga
  );
  yield takeEvery(
    'dashboard/getProductInlateFactory',
    getProductInlateFactorySaga
  );
  yield takeEvery(
    'dashboard/getProductIncompleteFactory',
    getProductIncompleteFactorySaga
  );
  yield takeEvery('dashboard/downloadExcelFactory', downloadExcelFactorySaga);
  yield takeEvery('dashboard/downloadExcelBuilding', downloadExcelBuildingSaga);
}

export default DashboardFactory;
