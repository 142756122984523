import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {
    downloadExcel,
    importExcelData,
    resetImportPlanData,
    setDataNull2,
} from "../../store/order/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import UploadFile from "../../components/UploadFile";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { site } from "../../api/url_helper";
import "../../assets/scss/theme-base/index.css";
import { ProgressSpinner } from "primereact/progressspinner";

const ModalImport = ({ visible, onHide }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [clearFiles, setClearFiles] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [successOrder, setSuccessOrder] = useState(null);
    const [errorOrder, setErrorOrder] = useState(null);
    const [errorFileUrl, setErrorFileUrl] = useState(null);

    const [isResultDialogVisible, setIsResultDialogVisible] = useState(false);

    const { excelData, importData, errorImportData, errorExcelData } =
        useSelector(
            (state) => ({
                excelData: state.Production.excelData,
                importData: state.Production.importData,
                errorExcelData: state.Production.errorExcelData,
                errorImportData: state.Production.errorImportData,
            }),
            shallowEqual
        );

    const handleDownloadClick = () => {
        setLoading(true);
        dispatch(downloadExcel({ type: "plan" }));
    };

    const showToast = (severity, summary, detail) => {
        toast.current?.show({ severity, summary, detail, life: 3000 });
    };

    useEffect(() => {
        if (excelData) {
            setLoading(false);
            const { file } = excelData;
            if (file) {
                const fileUrl = `${site}/${file}`;
                const link = document.createElement("a");
                link.href = fileUrl;
                link.setAttribute("download", "Sample data.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(setDataNull2());
                showToast("success", "Thành công", "Tải thành công");
            }
        } else if (errorExcelData) {
            setLoading(false);
            showToast(
                "error",
                "Thất bại",
                errorExcelData.response.data.message
            );
            dispatch(setDataNull2());
        }
    }, [excelData, errorExcelData, dispatch]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: () => {
            setLoading(true);
            dispatch(importExcelData({ files: Array.from(selectedFiles) }));
        },
    });

    useEffect(() => {
        if (importData) {
            setLoading(false);
            setIsResultDialogVisible(true);
            setSuccessOrder(importData?.successOrder);
            setErrorOrder(importData?.errorOrder);
            const file = importData?.file;
            if (file) {
                const fileUrl = `${site}/${file}`;
                setErrorFileUrl(fileUrl);
            }
            formik.resetForm();
            dispatch(resetImportPlanData());
        } else if (errorImportData) {
            setLoading(false);
            const file = errorImportData?.file;
            if (file) {
                const fileUrl = `${site}/${file}`;
                const link = document.createElement("a");
                link.href = fileUrl;
                link.setAttribute("download", "Error data.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(resetImportPlanData());
            }
            showToast(
                "error",
                "Thất bại",
                errorImportData?.response?.data?.message
            );
        }
    }, [importData, errorImportData, dispatch, formik, onHide]);

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={visible}
                onHide={onHide}
                className="responsive-dialog"
                header="Thêm từ Excel"
                style={{ width: "40%" }}
            >
                {loading && (
                    <div className="flex justify-content-center align-items-center">
                        <ProgressSpinner />
                    </div>
                )}
                {!loading && (
                    <>
                        <p className="font-bold">Các bước</p>
                        <div className="bg-yellow-100 px-5 py-3 line-height-4 border-round-md my-4">
                            <span>
                                1. Tải file mẫu và nhập dữ liệu (
                                <a
                                    className="text-blue-500 font-bold underline cursor-pointer"
                                    onClick={handleDownloadClick}
                                >
                                    Tải file mẫu
                                </a>
                                )
                            </span>
                            <p>2. Nhập dữ liệu chính xác theo các trường.</p>
                            <p className="text-red-500">
                                Lưu ý: Không thay đổi tên và thứ tự các cột.
                            </p>
                        </div>
                        <UploadFile
                            onUpload={() => {}}
                            getFiles={setSelectedFiles}
                            clearFiles={clearFiles}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "40px",
                                gap: "1rem",
                            }}
                        >
                            <Button
                                label="Hủy"
                                className="px-5 py-2 bg-white border-none text-400"
                                severity="secondary"
                                onClick={onHide}
                            />
                            <Button
                                label="Nhập"
                                className="px-5 py-2 bg-green-700"
                                onClick={formik.handleSubmit}
                                type="submit"
                            />
                        </div>
                    </>
                )}
            </Dialog>
            <Dialog
                visible={isResultDialogVisible}
                onHide={() => setIsResultDialogVisible(false)}
                className="responsive-dialog"
                header="Kết quả nhập dữ liệu"
                style={{ width: "40%" }}
            >
                <div className="surface-100 border-round-md p-3 line-height-4">
                    <div className="font-bold text-green-700">
                        Thành công: {successOrder}
                    </div>
                    <span className="text-red-500 font-bold">
                        Thất bại: {errorOrder}
                        <a
                            href={errorFileUrl}
                            className="text-blue-500 underline cursor-pointer ml-2"
                            download="Error data.xlsx"
                        >
                            Tải file
                        </a>
                    </span>
                </div>
            </Dialog>
        </>
    );
};

export default ModalImport;
