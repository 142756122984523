import { call, put, takeEvery } from "redux-saga/effects";
import {
    getDashboardQuantityFail,
    getDashboardQuantitySuccess,
    downloadExcelQauntitySuccess,
    downloadExcelQuantityFail,
} from "./slice";
import { getDashboardQuantity, exportExcel } from "../../api/backend_helper";

function* getDashboardQuantitySaga(action) {
    try {
        const {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            status,
        } = action.payload;
        const data = yield call(getDashboardQuantity, {
            page,
            limit,
            timeStartReq,
            timeEndReq,
            building,
            orderCode,
            category,
            factoryCode,
            status,
        });
        yield put(getDashboardQuantitySuccess(data));
    } catch (error) {
        yield put(getDashboardQuantityFail(error.message));
    }
}

function* downloadExcelTeamSaga(action) {
    try {
        const data = yield call(exportExcel, action.payload);
        yield put(downloadExcelQauntitySuccess(data));
    } catch (error) {
        yield put(downloadExcelQuantityFail(error));
    }
}

function* DashboardQuantity() {
    yield takeEvery(
        "dashboardQuantity/getDashboardQuantity",
        getDashboardQuantitySaga
    );
    yield takeEvery(
        "dashboardQuantity/downloadExcelQuantity",
        downloadExcelTeamSaga
    );
}

export default DashboardQuantity;
