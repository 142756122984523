import FormatDate from "../Report/FormatDate";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from "../../assets/images/logo.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const defaultImageUrl =
    "https://th.bing.com/th/id/OIP.CF-nc_bSeMzAbjG7KsjXlQHaHa?rs=1&pid=ImgDetMain";

const getBase64Image = (img) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(img);
    });
};
const fetchImageAsBase64 = async (imagePath) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const base64Image = await getBase64Image(blob);
    return base64Image;
};

const compressImage = async (imageFile, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
                (blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                },
                "image/jpeg",
                quality
            );
        };

        img.onerror = (error) => reject(error);
    });
};

const getBase64FromUrl = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        if (!blob.type.startsWith("image/")) {
            throw new Error("Invalid image format");
        }

        const compressedBase64 = await compressImage(blob, 300, 300, 0.7);
        return compressedBase64;
    } catch (error) {
        console.error("Error converting image to base64:", error);
        try {
            const defaultResponse = await fetch(defaultImageUrl);
            const defaultBlob = await defaultResponse.blob();
            const defaultCompressedBase64 = await compressImage(
                defaultBlob,
                300,
                300,
                0.7
            );
            return defaultCompressedBase64;
        } catch (defaultError) {
            console.error("Error loading default image:", defaultError);
            return null;
        }
    }
};

const prepareDataTableWithImages = async (datatable) => {
    const updatedDataTable = await Promise.all(
        datatable.map(async (product) => {
            const base64Image = await getBase64FromUrl(product.categoryImage);
            return {
                ...product,
                categoryImage: base64Image,
            };
        })
    );

    return updatedDataTable;
};

export const exportQuantityPDF = async (
    action,
    startDate,
    endDate,
    datatable,
    data,
    buildingName
) => {
    const updatedDataTable = await prepareDataTableWithImages(datatable);

    const imagePath = logo;

    const logoBase64 = await fetchImageAsBase64(imagePath);
    // Format totals
    const formattedTotals = {
        quantityPlan: data?.totalProductionPlan?.toLocaleString("vi-VN"),
        productionOutput:
            data?.totalProductionPlanPrice?.toLocaleString("vi-VN"),
        notImplemented: data?.totalNotImplementedPrice?.toLocaleString("vi-VN"),
        working: data?.totalWorkingPrice?.toLocaleString("vi-VN"),
        complete: data?.totalCompletePrice?.toLocaleString("vi-VN"),
    };

    const summaryRow = [
        { text: "Tổng cộng", bold: true, colSpan: 12 }, // Span over the first 4 columns
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {
            text: formattedTotals.quantityPlan,
            alignment: "right",
        },
        // { text: "" },
        { text: formattedTotals.productionOutput, alignment: "right" },
        { text: formattedTotals.notImplemented, alignment: "right" },
        { text: formattedTotals.working, alignment: "right" },
        { text: formattedTotals.complete, alignment: "right" },
    ];
    // const updatedDataTable = await prepareDataTableWithImages(datatable);
    const docDefinition = {
        pageSize: { width: 1400, height: 900 },
        pageMargins: [40, 60, 40, 60],
        content: [
            {
                image: logoBase64,
                width: 40,
                absolutePosition: { x: 40, y: 50 },
            },
            { text: buildingName, absolutePosition: { x: 100, y: 60 } },
            {
                text: "Báo cáo sản lượng",
                style: "header",
                margin: [0, 0, 0, 20],
            },
            {
                text: `Từ ngày: ${startDate}`,
                style: "subheader",
                margin: [0, 0, 0, 5],
            },
            {
                text: `Đến ngày: ${endDate}`,
                style: "subheader",
                margin: [0, 0, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    keepWithHeaderRows: 1,
                    widths: [
                        "auto",
                        50,
                        50,
                        100,
                        "auto",
                        50,
                        50,
                        80,
                        80,
                        "auto",
                        100,
                        100,
                        100,
                        70,
                        70,
                        70,
                        70,
                    ],
                    body: [
                        [
                            {
                                text: "STT",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Mã CT",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Mã ĐH",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Hạng mục",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Hình ảnh",
                                bold: true,
                                fillColor: "#2980BA",
                                color: "#ffffff",
                                alignment: "center",
                            },
                            {
                                text: "Số lượng",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "ĐVT",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Mã sản phẩm",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Mã nhà máy",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Kế hoạch hoàn thành",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Trạng thái",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Định vị",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Số lượng kế hoạch",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            // {
                            //     text: "Đơn giá",
                            //     fillColor: "#f5f5f5",
                            //     bold: true,
                            //     // alignment: "center",
                            //     fillColor: "#2980BA",
                            //     color: "#ffffff",
                            // },
                            {
                                text: "Sản lượng kế hoạch",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Chưa thực hiện",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Đang thực hiện",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                            {
                                text: "Hoàn thành",
                                bold: true,
                                alignment: "center",
                                fillColor: "#2980BA",
                                color: "#ffffff",
                            },
                        ],
                        summaryRow,
                        ...updatedDataTable?.map((product, index) => [
                            { text: index + 1, alignment: "center" },
                            {
                                text: product.Building.buildingCode,
                                alignment: "center",
                            },
                            { text: product.orderCode, alignment: "center" },
                            { text: product.categoryName },
                            {
                                image: product.categoryImage,
                                width: 50,
                                height: 50,
                                alignment: "center",
                            },
                            { text: product.quantity, alignment: "center" },
                            { text: product.unit, alignment: "center" },
                            { text: product.productCode, alignment: "center" },
                            { text: product.factoryCode, alignment: "center" },
                            {
                                text: FormatDate(product.intendFinishDate),
                                alignment: "center",
                            },
                            { text: product.status, alignment: "center" },
                            { text: product.teamWorking, alignment: "center" },
                            {
                                text: product.totalProductionPlan,
                                alignment: "right",
                            },
                            // { text: product.price },
                            {
                                text:
                                    (
                                        (Number(product.totalProductionPlan) ||
                                            0) * (Number(product.price) || 0)
                                    )?.toLocaleString("vi-VN") || 0,
                                alignment: "right",
                            },
                            {
                                text:
                                    (
                                        (Number(
                                            product?.TempProduct?.notImplemented
                                        ) || 0) * (Number(product.price) || 0)
                                    )?.toLocaleString("vi-VN") || 0,
                                alignment: "right",
                            },
                            {
                                text:
                                    (
                                        (Number(
                                            product?.TempProduct?.working
                                        ) || 0) * (Number(product.price) || 0)
                                    )?.toLocaleString("vi-VN") || 0,
                                alignment: "right",
                            },
                            {
                                text:
                                    (
                                        (Number(
                                            product?.TempProduct?.complete
                                        ) || 0) * (Number(product.price) || 0)
                                    )?.toLocaleString("vi-VN") || 0,
                                alignment: "right",
                            },
                        ]),
                    ],
                },
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
            },
        },
        defaultStyle: {
            font: "Roboto",
        },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    if (action === "print") {
        pdfDocGenerator.getBlob((blob) => {
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, "_blank");
            if (newWindow) {
                newWindow.onload = () => {
                    newWindow.print();
                };
            }
        });
    } else if (action === "save") {
        pdfDocGenerator.download("quanity.pdf");
    }
};
