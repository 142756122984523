import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getTeams,
  addNewTeams,
  updateTeams,
  deleteTeams,
  checkPassword,
} from '../../api/backend_helper';
import {
  getDataAll,
  getDataAllFail,
  getDataAllSuccess,
  addTeams,
  addTeamsFail,
  addTeamsSuccess,
  updateTeams as updateTeamsAction,
  updateTeamsFail,
  updateTeamsSuccess,
  deleteTeamsFail,
  deleteTeamsSuccess,
  addQrTeamFail,
  addQrTeamSuccess,
  addQrNoteSuccess,
  addQrNoteFail,
  checkPasswordSuccess,
  checkPasswordFail,
} from './slice';
import axios from 'axios';
import { ADD_QR_NOTE, ADD_QR_TEAM } from '../../api/url_helper';

const token = localStorage.getItem('accessToken');

function* getTeamsData(action) {
  try {
    const query = action.payload;
    const data = yield call(getTeams, query);
    yield put(getDataAllSuccess(data));
  } catch (error) {
    yield put(getDataAllFail(error.message));
  }
}

function* createTeamsSaga(action) {
  try {
    const data = yield call(addNewTeams, action.payload);
    yield put(addTeamsSuccess(data));
  } catch (error) {
    yield put(addTeamsFail(error));
  }
}

function* modifyTeams(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateTeams, id, data);
    yield put(updateTeamsSuccess(response));
  } catch (error) {
    yield put(updateTeamsFail(error));
  }
}

function* removeTeams({ payload }) {
  try {
    const response = yield call(deleteTeams, payload.id, { flag: true });
    yield put(deleteTeamsSuccess(response));
  } catch (error) {
    yield put(deleteTeamsFail(error));
  }
}

function* checkPasswordSaga(action) {
  try {
    const data = yield call(checkPassword, action.payload);
    yield put(checkPasswordSuccess(data));
  } catch (error) {
    yield put(checkPasswordFail(error));
  }
}

function* addQrTeamSaga(action) {
  try {
    const { values, files } = action.payload;
    const data = new FormData();
    data.append('teamName', values.teamName);
    data.append('factoryCode', values.factoryCode);
    data.append('note', values.description);
    data.append('quantityMade', values.quantityMade);
    data.append('type', values.type);
    if (files) {
      for (let i = 0; i < files.length; i++) {
        data.append('images', files[i]);
      }
    }

    // const response = yield call(addQrTeam, data );

    const response = yield call(
      axios.post,
      ADD_QR_TEAM + '?current=produce',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    yield put(addQrTeamSuccess(response.data));
  } catch (error) {
    yield put(addQrTeamFail(error));
  }
}

function* addQrNoteSaga(action) {
  try {
    const { values, files } = action.payload;
    const data = new FormData();
    data.append('teamName', values.teamName);
    data.append('factoryCode', values.factoryCode);
    data.append('note', values.note);
    if (files) {
      for (let i = 0; i < files.length; i++) {
        data.append('images', files[i]);
      }
    }

    const response = yield call(axios.post, ADD_QR_NOTE, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(addQrNoteSuccess(response.data));
  } catch (error) {
    yield put(addQrNoteFail(error));
  }
}

function* TeamsSaga() {
  yield takeEvery(getDataAll.type, getTeamsData);
  yield takeEvery(addTeams.type, createTeamsSaga);
  yield takeEvery(updateTeamsAction.type, modifyTeams);
  yield takeEvery('teams/deleteTeams', removeTeams);
  yield takeEvery('teams/addQrTeam', addQrTeamSaga);
  yield takeEvery('teams/addQrNote', addQrNoteSaga);
  yield takeEvery('teams/checkPassword', checkPasswordSaga);
}

export default TeamsSaga;
