import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { formatDate } from '../../components/utils';
import { getStep, setDataNull2 } from '../../store/order/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { site } from '../../api/url_helper';

function ModalAssignment2({ isShow, onHide, factoryCode }) {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [teamWorking, setTeamWorking] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [intendFinishDate, setIntendFinishDate] = useState('');

  const { stepData, orderData } = useSelector(
    (state) => ({
      stepData: state.Production.stepData,
      orderData: state.Production.orderData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isShow && factoryCode) {
      dispatch(getStep({ page: 1, limit: 100, factoryCode }));
    }
  }, [dispatch, isShow, factoryCode]);

  useEffect(() => {
    if (isShow && factoryCode) {
      axios
        .get(
          `${site}/api/products?page=1&limit=10&factoryCode=${factoryCode}&status=T%E1%BA%A5t+c%E1%BA%A3&current=plan`
        )
        .then((response) => {
          if (response?.data?.success) {
            const productData = response?.data?.data?.products?.items;
            if (productData.length > 0) {
              setCategoryName(productData[0].categoryName);
              setIntendFinishDate(productData[0].intendFinishDate);
              setTeamWorking(productData[0].teamWorking);
            }
          } else {
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          alert('Sever đang có vẫn đề, vui lòng thử lại sau');
        })
        .finally(() => {
          console.log();
        });
    }
  }, [isShow, factoryCode]);

  //   useEffect(() => {
  //     if(isShow)
  //     dispatch(
  //       getDataAll({
  //         page: 1,
  //         limit: 100,
  //       })
  //     );
  //   }, [dispatch,isShow]);

  return (
    <Dialog
      header='Phân công sản xuất'
      visible={isShow}
      style={{ width: '45vw', minWidth: '400px' }}
      className='responsive-dialog'
      onHide={() => {
        onHide();
        dispatch(setDataNull2());
      }}>
      <Toast ref={toast} />
      <div className='flex flex-column justify-content-between pb-2'>
        <div className='mb-2'>
          Hạng mục : <span className='font-bold'>{categoryName}</span>
        </div>
        <div className='mb-2'>
          Kế hoạch hoàn thành :
          <span className='font-bold'>{formatDate(intendFinishDate)}</span>
        </div>
      </div>
      <DataTable
        emptyMessage='Không có dữ liệu'
        value={stepData?.data?.items || []}
        tableStyle={{ minWidth: '20rem' }}>
        <Column
          field='teamName'
          header='Tổ đội sản xuất'
          body={(rowData) => {
            const style =
              rowData.teamName === teamWorking ? { color: 'red' } : {};
            return <span style={style}>{rowData.teamName}</span>;
          }}></Column>
        <Column
          field='quantity'
          header='SL'></Column>
        <Column
          field='startDate'
          header='Từ ngày'
          alignHeader={'center'}
          body={(rowData) => formatDate(rowData.startDate)}
          align={'center'}></Column>
        <Column
          field='endDate'
          header='Đến ngày'
          alignHeader={'center'}
          body={(rowData) => formatDate(rowData.endDate)}
          style={{ height: '40px' }}
          align={'center'}></Column>
      </DataTable>
    </Dialog>
  );
}

export default ModalAssignment2;
