// export const site = 'http://localhost:3101';
// export const api_address = 'https://address.itomo.vn';
// export const site_frontend = 'https://nem.itomo.one';

export const site = 'https://api.sanxuat.nemfurniture.com';
export const api_address = 'https://address.itomo.vn';
export const site_frontend = 'https://sanxuat.nemfurniture.com';

//------------------------------------------------------------------------------------------------------------//

// users
export const LOGIN = `${site}/api/users/login`;
export const ACTIVE = `${site}/api/users/active-user`;
export const VERIFY = `${site}/api/users/verify-user`;
export const CHANGE_PASSWORD = `${site}/api/users/change-password`;
// export const LOGOUT = `${site}/api/users/logout`;
export const GET_USER = `${site}/api/users`;
export const ADD_USER = `${site}/api/users/add-user`;
export const GET_USER_BY_USER_ID = `${site}/api/users/get-by-user-id`;
export const FORGET_PASSWORD = `${site}/api/users/forget-password`;
export const GET_AUTH = `${site}/api/users/auth`;

// permission
export const GET_PERMISSION = `${site}/api/permissions/group`;
export const GET_ALL_PERMISSION = `${site}/api/permissions`;
export const GET_PERMISSION_BY_GROUP_ID = `${site}/api/permissions/group/get-by-type`;
export const SET_PERMISSION_TO_GROUP = `${site}/api/permissions/group/set-permission-to-group`;

//production
export const GET_PRODUCTION = `${site}/api/productions`;

export const PRODUCTION = `${site}/api/productions`;

//construction
export const GET_CONSTRUCTION = `${site}/api/buildings`;
export const SET_CONSTRUCTION = `${site}/api/buildings`;
export const UPDATE_CONSTRUCTION = `${site}/api/buildings`;
export const DELETE_CONSTRUCTION = `${site}/api/buildings`;

//Teams
export const GET_TEAMS = `${site}/api/teams`;
export const SET_TEAMS = `${site}/api/teams`;
export const UPDATE_TEAMS = `${site}/api/teams`;
export const DELETE_TEAMS = `${site}/api/teams`;
export const ADD_QR_TEAM = `${site}/api/teams/add-team-production`;
export const ADD_QR_NOTE = `${site}/api/qualities`;
export const CHECK_PASSWORD = `${site}/api/teams/check-pass`;

//category
export const GET_CATEGORY = `${site}/api/categories`;
export const SET_CATEGORY = `${site}/api/categories`;
export const UPDATE_CATEGORY = `${site}/api/categories`;
export const DELETE_CATEGORY = `${site}/api/categories`;

//oder
export const GET_ORDER = `${site}/api/products`;
export const GET_PLAN = `${site}/api/plans`;
export const GET_STEP = `${site}/api/teams/steps`;
export const SET_PLAN = `${site}/api/plans`;
export const UPDATE_PLAN = `${site}/api/plans`;
export const DELETE_PLAN = `${site}/api/plans`;
export const SET_PRODUCT = `${site}/api/products`;
export const GET_BUILDING = `${site}/api/buildings`;

export const UPDATE_PRODUCTION = `${site}/api/products`;
export const GET_TEAM_PROGRESS = `${site}/api/products/get-team-progress-statistics`;
export const ADD_PRICE = `${site}/api/products`;

export const ADD_QR = `${site}/api/products`;

export const GET_TEAM_CHILDREN = `${site}/api/products/get-team-progress-statistics/children`;

export const GET_DVI_FULL = `${site}/api/teams/product`;

//dashboardfactory
export const GET_DASHBOARD_FACTORY = `${site}/api/dashboard/factory-statistics`;
export const GET_PRODUCT_INPROGRESS_FACTORY = `${site}/api/dashboard/factory-statistics/products-inprogress`;
export const GET_PRODUCT_INLATE_FACTORY = `${site}/api/dashboard/factory-statistics/products-inlate`;
export const GET_PRODUCT_INCOMPLETE_FACTORY = `${site}/api/dashboard/factory-statistics/products-incomplete`;

//dashboardTeams
export const GET_DASHBOARD_TEAMS = `${site}/api/dashboard/team-statistics`;
export const GET_DASHBOARD_TEAMS_WORKING = `${site}/api/dashboard/team-statistics/working`;
export const GET_DASHBOARD_TEAMS_DETAIL = `${site}/api/dashboard/team-statistics/details`;

//dashboardPlan
export const GET_DASHBOARD_PLAN = `${site}/api/dashboard/plan-statistics`;

//dashboardTechnical
export const GET_DASHBOARD_TECHNICAL = `${site}/api/dashboard/technical-statistics`;
export const GET_DASHBOARD_TECHNICAL_DETAIL = `${site}/api/dashboard/technical-statistics/details`;

//dashboardQuantity
export const GET_DASHBOARD_QUANTITY = `${site}/api/dashboard/plan-production-statistics`;

//product
export const GET_LIST_STATUS = `${site}/api/products/list-status`;

//excel

export const DOWNLOAD_EXCEL = `${site}/api/users/export-sample-excel`;
export const IMPORT_DATA_EXCEL = `${site}/api/users/import-data-excel`;
export const IMPORT_DATA_EXCEL2 = `${site}/api/users/import-data-excel/technical`;
export const IMPORT_DATA_EXCEL3 = `${site}/api/users/import-data-excel/technical`;
export const IMPORT_DATA_EXCEL4 = `${site}/api/users/import-data-excel/quantity`;

export const EXPORT_EXCEL = `${site}/api/users/export-data-excel`;

//quality
export const GET_REPORT_QUALITY = `${site}/api/qualities`;

//image
export const GET_REPORT_IMAGE = `${site}/api/teams/productions`;

//excel factory
export const EXPORT_EXCEL_FACTORY = `${site}/api/users/export-data-factory`;

//excel Team
export const EXPORT_EXCEL_TEAM = `${site}/api/users/export-data-team`;

//excel Building
export const EXPORT_EXCEL_BUILDING = `${site}/api/users/export-data-building`;
