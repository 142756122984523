// AddLocationPopup.js
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import UploadFile from '../../components/UploadFile';
import { InputTextarea } from 'primereact/inputtextarea';
import axios from 'axios';
import { PRODUCTION } from '../../api/url_helper';
import { useDispatch } from 'react-redux';
import { getTeamChildren } from '../../store/order/slice';
import { useNavigate } from 'react-router-dom';

const ModalEditLocation = ({ visible, onHide, data }) => {
  const toast = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clearFiles, setClearFiles] = useState(false);
  const [removeFiles, setRemoveFiles] = useState([]);

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const token = localStorage.getItem('accessToken');

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantityMade: data?.quantityMade || '',
      note: data?.note ? data.note : '',
      files: data?.images || [],
    },
    validationSchema: Yup.object({
      quantityMade: Yup.number().required(
        'Số lượng thực hiện không được để trống'
      ),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('quantityMade', values.quantityMade);
      formData.append('note', values.note);
      formData.append('time', getCurrentDateTime());
      if (selectedFiles) {
        Array.from(selectedFiles).forEach((file) =>
          formData.append('images', file)
        );
      }
      formData.append(
        'removeImages',
        removeFiles.map((file) => file)
      );

      if (data?.id) {
        axios
          .put(`${PRODUCTION}/${data?.id}`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            toast.current.show({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Cập nhật thông tin thành công',
              life: 3000,
            });
            onHide();
            dispatch(
              getTeamChildren({
                team: data.name,
                factoryCode: data.factoryCode,
              })
            );
          })
          .catch((err) => {
            toast.current.show({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data.message,
              life: 3000,
            });
          });
      }
    },
  });

  const onUpload = (e) => {};
  const getFiles = (files) => {
    setSelectedFiles(files);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header='Cập nhật thông tin'
        visible={visible}
        className='responsive-dialog'
        style={{ width: '450px' }}
        onHide={() => {
          onHide();
        }}
        footer={
          <Button
            label='Lưu'
            type='submit'
            onClick={formik.handleSubmit}
          />
        }>
        <form>
          <div className='flex flex-column w-12 mb-3'>
            <label className='mb-2'>Số lượng thực hiện</label>
            <InputText
              id='quantityMade'
              name='quantityMade'
              value={formik.values.quantityMade}
              onChange={formik.handleChange}
              style={{ height: '40px' }}
              className={
                formik.touched.quantityMade && formik.errors.quantityMade
                  ? 'p-invalid'
                  : ''
              }
            />
            {formik.touched.quantityMade && formik.errors.quantityMade ? (
              <div style={{ color: 'red' }}>
                {formik.errors.quantityMade.toString()}
              </div>
            ) : null}
          </div>
          <div className='flex flex-column w-12 mb-3'>
            <label className='mb-2'>Ghi chú</label>
            <InputTextarea
              id='note'
              name='note'
              value={formik.values.note}
              autoResize
              onChange={formik.handleChange}
              className={
                formik.touched.note && formik.errors.note ? 'p-invalid' : ''
              }
            />
            {formik.touched.note && formik.errors.note ? (
              <div style={{ color: 'red' }}>
                {formik.errors.note.toString()}
              </div>
            ) : null}
          </div>

          <UploadFile
            onUpload={onUpload}
            getFiles={getFiles}
            clearFiles={clearFiles}
            initialFiles={formik.values.files}
            setRemoveFiles={setRemoveFiles}
          />
        </form>
      </Dialog>
    </>
  );
};

export default ModalEditLocation;
