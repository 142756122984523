import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listStatus: null,
  error: null,
  loading: false,
  updateLocation: {
    data: null,
    error: null,
    loading: false,
  },
  deleteLocation: {
    data: null,
    error: null,
    loading: false,
  },
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getListStatus: (state) => {
      state.loading = true;
    },
    getListStatusSuccess: (state, action) => {
      state.loading = false;
      state.listStatus = action.payload;
      state.error = null;
    },
    getListStatusFail: (state, action) => {
      state.loading = false;
      state.listStatus = null;
      state.error = action.payload;
    },
    updateLocation: (state) => {
      state.updateLocation.loading = true;
    },
    updateLocationSuccess: (state, action) => {
      state.updateLocation.loading = false;
      state.updateLocation.data = action.payload;
      state.updateLocation.error = null;
    },
    updateLocationFail: (state, action) => {
      state.updateLocation.loading = false;
      state.updateLocation.data = null;
      state.updateLocation.error = action.payload;
    },
    deleteLocation: (state) => {
      state.deleteLocation.loading = true;
    },
    deleteLocationSuccess: (state, action) => {
      state.deleteLocation.loading = false;
      state.deleteLocation.data = action.payload;
      state.deleteLocation.error = null;
    },
    deleteLocationFail: (state, action) => {
      state.deleteLocation.loading = false;
      state.deleteLocation.data = null;
      state.deleteLocation.error = action.payload;
    },
    resetData: (state) => {
      state.updateLocation.data = null;
      state.updateLocation.error = null;
      state.deleteLocation.data = null;
      state.deleteLocation.error = null;
    },
  },
});

export const {
  getListStatus,
  getListStatusFail,
  getListStatusSuccess,
  updateLocation,
  updateLocationFail,
  updateLocationSuccess,
  deleteLocation,
  deleteLocationFail,
  deleteLocationSuccess,
  resetData,
} = productSlice.actions;

export default productSlice.reducer;
