import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import HeaderReportTechnical from "./HeaderReportTechnical";
import TableReportTechnical from "./TableReportTechnical";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getDashboardTechnical,
    getDashboardTechnicalDetail,
} from "../../store/dashboardTechnical/slice";
import { exportTechnicalPDF } from "./ExportTechnicalPDF";
import FormatDate from "../Report/FormatDate";
import { DataContext } from "../../contexts/data/DataProvider";
import {
    downloadExcelQuantity,
    setExcelDataQuantityNull,
} from "../../store/dashboardQuantity/slice";
import { formatDateApi } from "../../feature/formatDateApi";
import { site } from "../../api/url_helper";
import { Toast } from "primereact/toast";

export default function ReportTechnical() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [nameBuilding, setNameBuilding] = useState("");
    const [handleChildClick, setHandleChildClick] = useState(() => () => {});
    const [pagination, setPagination] = useState({ first: 0, rows: 10 });
    const [tabMenuActive, setTabMenuActive] = useState(0);
    const downloadLink = useRef(null);
    const [excelFileUrl, setExcelFileUrl] = useState("");

    const handleTabMenuChange = (newTabMenuActive) => {
        setTabMenuActive(newTabMenuActive);
    };

    const handlePaginationChange = (first, rows) => {
        setPagination({ first, rows });
    };

    // const childClickHandler = useCallback((handler) => {
    //     setHandleChildClick(() => handler);
    // }, []);

    // const handleClickInParent = () => {
    //     if (handleChildClick) {
    //         handleChildClick();
    //     }
    // };
    const { buildingData, exportExcelQuantity, exportExcelQuantityError } =
        useSelector(
            (state) => ({
                exportExcelQuantityError:
                    state.DashboardQuantity.errorExcelData,
                exportExcelQuantity: state.DashboardQuantity.excelData,
                buildingData: state.Production.dataBuilding,
            }),
            shallowEqual
        );

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (id) {
            dispatch(
                downloadExcelQuantity({
                    type: "technical",
                    building_id: id,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelQuantity({
                    type: "technical",
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (exportExcelQuantity) {
            const url = `${site}/${exportExcelQuantity.file}`;
            setExcelFileUrl(url);
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, exportExcelQuantity]);

    useEffect(() => {
        if (exportExcelQuantityError) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: exportExcelQuantityError.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataQuantityNull());
        }
    }, [dispatch, exportExcelQuantityError]);

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    const dashboardTechnicalData = useSelector(
        (state) => state.DashboardTechnical.dashboardTechnicalData,
        shallowEqual
    );

    const dashboardTechnicalDetailData = useSelector(
        (state) => state.DashboardTechnical.dashboardTechnicalDetailData,
        shallowEqual
    );

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getDashboardTechnical({
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        endDate,
        startDate,
        building,
        orderCode,
        category,
        factoryCode,
    ]);

    useEffect(() => {
        let status = "Tất cả";
        if (dashboardTechnicalDetailData) {
            status =
                dashboardTechnicalDetailData.data.listStatus[tabMenuActive]
                    ?.label;
        }

        if (startDate && endDate) {
            dispatch(
                getDashboardTechnicalDetail({
                    page:
                        (pagination.first + pagination.rows) / pagination.rows,
                    limit: pagination.rows,
                    status,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        building,
        orderCode,
        category,
        factoryCode,
        startDate,
        endDate,
        // limit,
        pagination,
        tabMenuActive,
    ]);

    return (
        <div>
            <Toast ref={toast} />
            <a ref={downloadLink} style={{ display: "none" }} />
            <HeaderReport
                building={building}
                orderCode={orderCode}
                category={category}
                factoryCode={factoryCode}
                setBuilding={setBuilding}
                setOrderCode={setOrderCode}
                setCategory={setCategory}
                setFactoryCode={setFactoryCode}
                setStartDateOutput={setStartDate}
                setEndDateOutput={setEndDate}
                exportClick={(e) =>
                    exportTechnicalPDF(
                        "save",
                        FormatDate(startDate),
                        FormatDate(endDate),
                        dashboardTechnicalData?.data,
                        dashboardTechnicalDetailData?.data?.products?.items,
                        nameBuilding
                    )
                }
                printReport={(e) =>
                    exportTechnicalPDF(
                        "print",
                        FormatDate(startDate),
                        FormatDate(endDate),
                        dashboardTechnicalData?.data,
                        dashboardTechnicalDetailData?.data?.products?.items,
                        nameBuilding
                    )
                }
                // exportExcel={exportExcelHandle}
                exportExcel={exportExcelHandle}
            />
            <HeaderReportTechnical
                dashboardTechnicalData={dashboardTechnicalData}
            />
            <TableReportTechnical
                dashboardTechnicalDetailData={dashboardTechnicalDetailData}
                // onButtonClick={childClickHandler}
                onPaginationChange={handlePaginationChange}
                onTabMenuChange={handleTabMenuChange}
            />
        </div>
    );
}
