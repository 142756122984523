import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderReport from "../../layout/HeaderReport/HeaderReport";
import ColumnChart from "./ColumnChart";
import Progress from "./Progress";
import TeamDetail from "./TeamDetail";
import ColumnChartPDF from "./ColumnChartPDF";
import {
    getDashboardTeams,
    getDashboardTeamsDetail,
    getDashboardTeamsWorking,
    setExcelDataTeamNull,
} from "../../store/dashboardTeams/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../assets/scss/theme-base/dashboard.css";
import { exportTeamPDF } from "./ExportTeamPDF";
import FormatDate from "../Report/FormatDate";
import { DataContext } from "../../contexts/data/DataProvider";
import { downloadExcelTeam } from "../../store/dashboardTeams/slice";
import { Toast } from "primereact/toast";
import { site } from "../../api/url_helper";
import { formatDateApi } from "../../feature/formatDateApi";

export default function ReportTeam() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const downloadLink = useRef(null);

    const [building, setBuilding] = useState(null);
    const [orderCode, setOrderCode] = useState("");
    const [category, setCategory] = useState("");
    const [factoryCode, setFactoryCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedStatus, setSelectedStatus] = useState({
        name: "",
        code: "",
    });
    const [team, setTeam] = useState("");
    const [nameBuilding, setNameBuilding] = useState("");
    const [pagination, setPagination] = useState({ first: 0, rows: 10 });
    const [pagination1, setPagination1] = useState({ first: 0, rows: 10 });

    const handlePaginationChange = (first, rows) => {
        setPagination({ first, rows });
    };
    const handlePaginationChange1 = (first, rows) => {
        setPagination1({ first, rows });
    };

    const { buildingName } = useContext(DataContext);
    useEffect(() => {
        if (buildingName) setNameBuilding(buildingName);
    }, [buildingName]);

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
    };

    const handleTeamChange = (teamName) => {
        setTeam(teamName);
    };

    const dashboardTeams = useSelector(
        (state) => state.DashboardTeams.dashboardTeamsData,
        shallowEqual
    );

    const dashboardTeamsDetail = useSelector(
        (state) => state.DashboardTeams.dashboardTeamsDetailData,
        shallowEqual
    );

    const dashboardTeamsWorking = useSelector(
        (state) => state.DashboardTeams.dashboardTeamsWorkingData,
        shallowEqual
    );

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getDashboardTeams({
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
    ]);

    useEffect(() => {
        if ((startDate, endDate)) {
            dispatch(
                getDashboardTeamsDetail({
                    page:
                        (pagination.first + pagination.rows) / pagination.rows,
                    limit: pagination.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                    team,
                    status: selectedStatus?.code,
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        team,
        selectedStatus,
        pagination,
    ]);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(
                getDashboardTeamsWorking({
                    page:
                        (pagination1.first + pagination1.rows) /
                        pagination1.rows,
                    limit: pagination1.rows,
                    timeStartReq: startDate,
                    timeEndReq: endDate,
                    building,
                    orderCode,
                    category,
                    factoryCode,
                })
            );
        }
    }, [
        dispatch,
        startDate,
        endDate,
        building,
        orderCode,
        category,
        factoryCode,
        pagination1,
    ]);

    //export excel
    const { buildingData, excelDataTeam, errorExcelDataTeam } = useSelector(
        (state) => ({
            excelDataTeam: state.DashboardTeams.excelDataTeam,
            errorExcelDataTeam: state.DashboardTeams.errorExcelDataTeam,
            buildingData: state.Production.dataBuilding,
        }),
        shallowEqual
    );

    const exportExcelHandle = () => {
        const id = buildingData?.data?.items.find(
            (item) => item.buildingCode === building
        )?.id;

        if (id) {
            dispatch(
                downloadExcelTeam({
                    building_id: id,
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        } else {
            dispatch(
                downloadExcelTeam({
                    timeStartReq: formatDateApi(startDate),
                    timeEndReq: formatDateApi(endDate),
                })
            );
        }
    };

    useEffect(() => {
        if (excelDataTeam) {
            const url = `${site}/${excelDataTeam.file}`;
            toast.current.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xuất excel thành công.",
                life: 3000,
            });
            dispatch(setExcelDataTeamNull());

            // Tự động tải file
            downloadLink.current.href = url;
            downloadLink.current.download = "exported_data.xlsx";
            downloadLink.current.click();
        }
    }, [dispatch, excelDataTeam]);

    useEffect(() => {
        if (errorExcelDataTeam) {
            toast.current.show({
                severity: "error",
                summary: "Thất bại",
                detail: errorExcelDataTeam.response.data.message,
                life: 3000,
            });
            dispatch(setExcelDataTeamNull());
        }
    }, [dispatch, errorExcelDataTeam]);

    return (
        <>
            <div>
                <Toast ref={toast} />
                <a ref={downloadLink} style={{ display: "none" }} />
                <HeaderReport
                    building={building}
                    orderCode={orderCode}
                    category={category}
                    factoryCode={factoryCode}
                    setBuilding={setBuilding}
                    setOrderCode={setOrderCode}
                    setCategory={setCategory}
                    setFactoryCode={setFactoryCode}
                    setStartDateOutput={setStartDate}
                    setEndDateOutput={setEndDate}
                    exportClick={(e) =>
                        exportTeamPDF(
                            "save",
                            FormatDate(startDate),
                            FormatDate(endDate),
                            dashboardTeamsWorking?.data?.items,
                            dashboardTeamsDetail?.data?.items,
                            nameBuilding
                        )
                    }
                    printReport={(e) =>
                        exportTeamPDF(
                            "print",
                            FormatDate(startDate),
                            FormatDate(endDate),
                            dashboardTeamsWorking?.data?.items,
                            dashboardTeamsDetail?.data?.items,
                            nameBuilding
                        )
                    }
                    exportExcel={exportExcelHandle}
                />
            </div>
            <div
                id="column-chart"
                style={{
                    width: "100%",
                    height: "400px",
                    position: "absolute",
                    left: "-9999999999px",
                    transform: "scale(0.5)",
                }}
            >
                <ColumnChartPDF dashboardTeams={dashboardTeams} />
            </div>
            <div className="mt-3 border-round-lg shadow-1">
                <ColumnChart dashboardTeams={dashboardTeams} />
            </div>
            <div className="flex team-container flex-column mt-3 gap-3 ">
                <div className="w-full shadow-1 border-round-lg">
                    <Progress
                        dashboardTeamsWorking={dashboardTeamsWorking}
                        onPaginationChange={handlePaginationChange1}
                    />
                </div>
                <div className="w-full shadow-1 border-round-lg">
                    <TeamDetail
                        dashboardTeamsDetail={dashboardTeamsDetail}
                        onStatusChange={handleStatusChange}
                        onTeamChange={handleTeamChange}
                        onPaginationChange={handlePaginationChange}
                    />
                </div>
            </div>
        </>
    );
}
